import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Box,
  Container,
  Divider,
  Grid,
  Link as MuiLink,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Image from "next/legacy/image";
import { Trans, useTranslation } from "next-i18next";
import React, { ReactElement } from "react";
import ReactMarkdown from "react-markdown";

import { useContent } from "../ContentProvider";
import LinkText from "../LinkText";

const Link = styled(MuiLink)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: "0.8rem",
  textDecoration: "underline",
}));

const Footer = (): ReactElement => {
  const { t } = useTranslation();
  const { host } = useContent();
  const name = host?.name;
  const currentYear = new Date().getFullYear();

  return (
    <Container maxWidth="xs">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          my: 3,
        }}
      >
        {host == null ?
          <>
            <Box
              sx={{
                my: 3,
                width: 70,
                height: 70,
              }}
            >
              <Image
                layout="responsive"
                src="/find-a-helpline-brandmark.svg"
                width={150}
                height={150}
                alt={t(
                  "Find A Helpline brand mark with two chat bubbles in a heart shape",
                )}
              />
            </Box>
            <Box sx={{ textAlign: "center" }}>
              <Typography
                sx={{
                  fontSize: "0.8rem",
                }}
              >
                <Trans t={t}>
                  Find A Helpline is a public service made with ♡ by{" "}
                  <Link
                    href="https://www.throughlinecare.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    ThroughLine
                  </Link>
                  . For business or commercial use,{" "}
                  <Link
                    href="https://www.throughlinecare.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    get in touch
                  </Link>
                  .
                </Trans>
              </Typography>
            </Box>
          </>
        : <>
            <Typography
              sx={{
                fontSize: "0.8rem",
              }}
              gutterBottom
            >
              {host.footerByline ?
                // We are using ReactMarkdown directly so we can limited the supported features for the byline.
                <ReactMarkdown allowedElements={["a", "p", "em", "strong"]}>
                  {host.footerByline}
                </ReactMarkdown>
              : t(
                  "Brought to you in partnership to provide free, accessible mental health support anywhere, anytime.",
                )
              }
            </Typography>
            {host.partnerLogo && (
              <Box
                sx={{
                  textAlign: "center",
                  maxWidth: host.partnerLogoMaxWidth || null,
                  my: 3,
                }}
              >
                <Image
                  src={host.partnerLogo.url}
                  alt={host.name}
                  layout="intrinsic"
                  width={host.partnerLogo.width}
                  height={host.partnerLogo.height}
                  priority
                />
              </Box>
            )}
            <Typography
              sx={{
                fontSize: "0.8rem",
              }}
            >
              {host.homePageUrl ?
                <Link
                  href={host.homePageUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("Provided by {{name}}", { name })}
                </Link>
              : t("Provided by {{name}}", { name })}
            </Typography>
            <Typography
              sx={{
                fontSize: "0.8rem",
              }}
            >
              <Link
                href="https://www.throughlinecare.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("Powered by ThroughLine")}
              </Link>
            </Typography>
          </>
        }
      </Box>
      <Divider />
      <Grid
        container
        sx={{
          my: 3,
          mx: "auto",
          maxWidth: 220,
        }}
        spacing={1}
      >
        {(host == null || host.pageAbout) && (
          <Grid item xs={6}>
            <LinkText
              href="/about"
              data-testid="about"
              underline
              sx={{ fontSize: "0.8rem" }}
            >
              {t("About")}
            </LinkText>
          </Grid>
        )}
        {(host == null || host.pageFaqs) && (
          <Grid item xs={6}>
            <LinkText
              href="/faq"
              data-testid="faq"
              underline
              sx={{ fontSize: "0.8rem" }}
            >
              {t("FAQs")}
            </LinkText>
          </Grid>
        )}
        {(host == null || host.pageGetTheWidget) && (
          <Grid item xs={6}>
            <Link
              data-testid="integrations"
              target="_blank"
              href="https://www.throughlinecare.com"
            >
              {t("Integrations")}{" "}
              <OpenInNewIcon
                fontSize="inherit"
                style={{ verticalAlign: "middle" }}
              />
            </Link>
          </Grid>
        )}
        {(host == null || host.pageTermsOfService) && (
          <Grid item xs={6}>
            <LinkText
              href="/terms"
              data-testid="terms"
              underline
              sx={{ fontSize: "0.8rem" }}
              locale="en"
            >
              {t("Terms of Service")}
            </LinkText>
          </Grid>
        )}
        {(host == null || host.pageContact) && (
          <Grid item xs={6}>
            <LinkText
              href="/contact"
              data-testid="contact"
              underline
              sx={{ fontSize: "0.8rem" }}
            >
              {t("Contact")}
            </LinkText>
          </Grid>
        )}
        {(host == null || host.pagePrivacyPolicy) && (
          <Grid item xs={6}>
            <LinkText
              href="/privacy"
              data-testid="privacy"
              underline
              sx={{ fontSize: "0.8rem" }}
              locale="en"
            >
              {t("Privacy Policy")}
            </LinkText>
          </Grid>
        )}
        {(host == null || host.pageSitemap) && (
          <Grid item xs={6}>
            <LinkText
              href="/sitemap"
              data-testid="sitemap"
              underline
              sx={{ fontSize: "0.8rem" }}
            >
              {t("Sitemap")}
            </LinkText>
          </Grid>
        )}
      </Grid>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          my: 3,
        }}
      >
        <Typography
          sx={{
            fontSize: "0.8rem",
          }}
          align="center"
        >
          <Trans
            t={t}
            i18nKey="Copyright ThroughLine Limited"
            values={{ year: currentYear }}
          >
            {/* Fallback content */}
            Copyright ©{" "}
            <Link
              href="https://www.throughlinecare.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              ThroughLine Limited
            </Link>
          </Trans>
          <br />
          {t("All rights reserved.")}
        </Typography>
      </Box>
    </Container>
  );
};

export default Footer;
